export default [
    // 登录页面
    {
        path: '/singleSignOn',
        name: 'singleSignOn',
        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/singleSignOn")
    },
    // 错误页面
    {
        path: '/errorPage',
        name: 'errorPage',
        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/errorPage")
    },
    // 无权限页面
    {
        path: '/noAccess',
        name: 'noAccess',
        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/noAccess")
    },
    {
        path: '/',
        name: 'home',
        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/home"),
        children:[
            // 最近查看
            {
                path: '/recentlyViewed',
                name: 'recentlyViewed',
                component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/recentlyViewed")
            },

            // 我分享的
            {
                path: '/share',
                name: 'share',
                component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/share")
            },
            // 目录管理
            {
                path: '/directoryManagement',
                name: 'directoryManagement',
                component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/directoryManagement")
            },
            // 目录管理(进入)
            {
                path: '/directoryManagementInternal',
                name: 'directoryManagementInternal',
                component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/directoryManagementInternal"),
                meta: {
                    realRouter: '/directoryManagement' // 菜单激活判断
                }
            },
            // 分享出去的页面
            {
                path: '/shareOutPage',
                name: 'shareOutPage',
                component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/shareOutPage"),
                meta: {
                    realRouter: '/directoryManagement' // 菜单激活判断
                }
            },
            // 架构管理
            {
                path: '/architectureManagement',
                name: 'architectureManagement',
                component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/architectureManagement")
            },
            // 人员管理
            {
                path: '/personnelManagement',
                name: 'personnelManagement',
                component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/personnelManagement"),
            },
            // 操作记录 history
            {
                path: '/history',
                name: 'history',
                component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/history"),
            },
            // 回收站 recycleBin
            {
                path: '/recycleBin',
                component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/recycleBin"),
                children: [
                    // 文件夹 recycleFolder
                    {
                        path: '',
                        name: 'recycleFolder',
                        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/recycleFolder"),
                        meta: {
                            realRouter: '/recycleBin' // 菜单激活判断
                        }
                    },
                    // 文件 recycleFiles
                    {
                        path: '/recycleFiles',
                        name: 'recycleFiles',
                        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/recycleFiles"),
                        meta: {
                            realRouter: '/recycleBin' // 菜单激活判断
                        }
                    },
                ]
            },

        ],
    },
]
