<template>
    <div id="app">
        <router-view v-if="routerAlive"></router-view>
    </div>
</template>
<script>
  export default {
    name: 'App',
    // 无感刷新
    provide() {
      return {
        reload: this.reload,
      }
    },
    data() {
      // 无感刷新参数
      return {routerAlive: true}
    },
    mounted() {
      // 关闭浏览器时 清除本地local值
      window.addEventListener('beforeunload', e => {
        window.localStorage.removeItem('user_lang')
      });
    },

    methods: {
      //重新加载的方法
      reload() {
        this.routerAlive = false
        this.$nextTick(function () {
          this.routerAlive = true
        })
      },
    },
  }
</script>
<style lang="scss">
body {
  margin: 0;
  padding: 0;
}

#app {
  margin: 0;
  padding: 0;
}

.el-pagination {
  margin-top: 15px;
}

.el-main {
  background: #F5F5FC;
  padding: 0;
}

.main-body {
  padding: 20px;
  background: #F1F5F8;

}

.el-breadcrumb {
  height: 50px;
  padding-left: 20px;
  margin-bottom: 20px;
  background: #fff;
  line-height: 50px;
}

.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 25%;
}

.spousePhone {
  display: flex;
}

.el-drawer__body {
  overflow-y: auto;
}

.el-timeline {
  padding: 0 30px;
}

.el-card__body {
  padding: 0 20px;
}

/*动态标签*/
.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.el-tag {
  cursor: pointer;
}

.unifiedWidth {
  width: 245px !important;
}

.el-card__header {
  background: #F5F7FA;
}

.el-card__body {
  padding: 0 30px;
}

.card-table {
  margin-top: 15px;
}
    body {
        margin: 0;
        padding: 0;
    }

    #app {
        margin: 0;
        padding: 0;
    }

    .el-pagination {
        margin-top: 15px;
    }

    .el-main {
        background: #F5F5FC;
        //padding: 20px !important;
    }

    .main-body {
        padding: 40px 40px 97px 0;
        background: #F5F7FB;
    }

    .el-breadcrumb {
        height: 50px;
        padding-left: 20px;
        margin-bottom: 20px;
        background: #fff;
        line-height: 50px;
    }

    .demo-table-expand {
        font-size: 0;
    }

    .demo-table-expand label {
        width: 90px;
        color: #99a9bf;
    }

    .demo-table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 25%;
    }

    .spousePhone {
        display: flex;
    }

    .el-drawer__body {
        overflow-y: auto;
    }

    .el-timeline {
        padding: 0 30px;
    }

    .el-card__body {
        padding: 0 20px;
    }

    /*动态标签*/
    .el-tag + .el-tag {
        margin-left: 10px;
    }

    .button-new-tag {
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
    }

    .el-tag {
        cursor: pointer;
    }

    .unifiedWidth {
        width: 245px !important;
    }

    .el-card__header {
        background: #F5F7FA;
    }

    .el-card__body {
        padding: 0 30px;
    }

    .card-table {
        margin-top: 15px;
    }

    .el-card {
        border: 1px solid rgba(192, 196, 204, .8);
    }

    .text {
        font-size: 14px;
    }

    .item {
        margin-bottom: 18px;
    }

    .remarks-table {
        display: flex;
    }

    .el-cascader-menu__wrap {
        height: 204px !important;
    }

    /*.no-states{*/
    /*  text-align: center;*/
    /*  width: 342px;*/
    /*  height: 237px;*/
    /*  background: #fff url('./assets/noStates.jpg') no-repeat center/85%;*/
    /*  margin: 0 auto;*/
    /*  position: relative;*/
    /*}*/

.no-states p {
  color: #72767b;
  position: absolute;
  top: 240px;
  left: 45%;
  text-align: center;
}
    .no-states p {
        color: #72767b;
        position: absolute;
        top: 240px;
        left: 45%;
        text-align: center;
    }

    .el-radio-button:first-child .el-radio-button__inner {
        border: none;
    }

    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        box-shadow: none;
    }
.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  box-shadow: none;
}

    .null-data {
        color: #909399;
    }

    .el-cascader-menu__list {
        padding: 6px 10px 6px 0;
    }

    .detail-click:hover {
        cursor: pointer;
    }

    /*优先标志*/
    .priority-span {
        display: inline-block;
        height: 15px;
        margin-left: 5px;
        padding: 3px 5px;
        border-radius: 4px;
        background: #EF3054;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
    }

    .popColor {
        opacity: 0.5;
    }
.popColor {
  opacity: 0.5;
}
</style>
